<!-- 设置密码( = 1) -->
<template>
  <r-e-dialog
      title="添加密码"
      show-footer
      class="layer-contract-detail"
      :visible.sync="dialogVisible"
      width="700px"
      top="10vh"
      @click-submit="clickSubmit"
      @click-cancel="clickCancel"
      @close="clickCancel"
  >
    <el-form
        ref="formPublish"
        label-width="90px"
        size="small"
        :model="formPublish"
        :rules="rules"
        :inline="true"
    >
      <div class="flex">
        <!-- 2023/08/21 张晓瑜 修改天数为单选框 -->
        <el-form-item label="天数：" prop="days" label-width="128px">
          <el-radio-group v-model="formPublish.days" style="width: 450px">
            <el-radio :label="1">1天</el-radio>
            <el-radio :label="3">3天</el-radio>
            <el-radio :label="5">5天</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="手机号:" prop="phone" label-width="120px">
          <el-input
              v-model="formPublish.phone"
              style="width: 450px"
              placeholder="请输入手机号"
          />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="删除历史密码:" prop="isDelete" label-width="120px">
          <el-radio-group v-model="formPublish.isDelete">
            <el-radio-button :label="false">保留</el-radio-button>
            <el-radio-button :label="true">删除</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
            <div class="flex">
              <el-form-item label="历史累计时长:" label-width="120px">
                <div>{{days}}</div>
              </el-form-item>
            </div>

      <el-form-item label="注意：" prop="days" label-width="128px">
        <div style="color: red">册除历史密码将使所有的密码不可用，请谨慎选择</div>
                <div style="color: red">当历史累计时长超过10天，则需审批通过方可发起</div>
      </el-form-item>
    </el-form>
<!--    <CipherProcessing ref="CipherProcessingRef"></CipherProcessing>-->

<!--    处理弹框-->
    <r-e-dialog :appendToBody="true"  title="处理" class="layer-contract-detail" :visible.sync="dialogVisibleson" :show-footer="type!=='chakan'"
                top="10vh"
                width="670px" :showFooter="false"
                fullscreen @close="clickCancel">
      <div style="height: 75vh;">
        <div style="height: 100%;width: 100%;">
          <el-steps :active="active" style="margin-bottom: 20px;" :finish-status="titleTyle" process-status="finish">
            <el-step title="发起">
              <div slot="description" >
                <div >申请人:{{ userName }}</div>
                <div>申请时间: </div>
              </div>
            </el-step>
            <el-step title="资产部审核">
              <div slot="description" v-if="active > 0 && formPublish.workOrderStatus !== '已撤销' ">
<!--                <div >审核人:</div>-->
<!--                <div>时间:</div>-->
              </div>
            </el-step>
            <el-step  title="管理层审核" v-if="topTlile">
<!--              <div slot="description" >-->
<!--                <div >审核人:</div>-->
<!--                <div>时间:</div>-->
<!--              </div>-->
            </el-step>
            <el-step title="查看"></el-step>
          </el-steps>

          <div style="height: 100%;display: flex;flex-flow: column wrap;">
            <!--         密码审批-->
            <div >
              <div class="box_tap">
                <h3>承租信息</h3>
              </div>
              <div class="box_bnt">
                <div class="item">
                  承租人：{{ formPublishSon.leasorName }}
                </div>
                <div class="item">
                  手机号码：{{ formPublishSon.leasorPhone }}
                </div>
                <div class="item">
                  证件号码：{{ formPublishSon.idCard }}
                </div>
                <div class="item">
                  承租人类型：{{ formPublishSon.leasorType === '个人' ? '个人' : '企业' }}
                </div>
                <div class="item">
                  房间：{{ formPublishSon.apartmentName }}
                </div>
                <div class="item">
                  合同周期：{{ formPublishSon.contractPeriod }}
                </div>
                <div class="item">
                </div>
                <div class="item">
                </div>
              </div>
            </div>
            <div >
              <div class="box_tap">
                <h3>密码信息</h3>
              </div>
              <div class="box_bnt" style="height: 150px">
                <div class="item">
                  申请天数：{{ formPublishSon.days }}天
                </div>
                <div class="item">
                  接收号码：{{ formPublishSon.phone }}
                </div>
                <div class="item">
                  历史累计时长：{{ formPublishSon.totalHistoryDurationStr }}
                </div>
                <div class="item">
                </div>
                <div class="item" style="display: flex;align-items: center">
                  <div style="width: 100px">申请原因：</div>
                  <el-input  type="textarea"
                            style="width: 350px;margin-top: 20px;margin-left: -30px" v-model="passApplyReason"
                            placeholder="请输入内容"></el-input>

                </div>
<!--                <div class="item" style="display: flex;align-items: center">-->
<!--                  <div style="width: 100px"> 申请原因：</div>-->
<!--                  <el-input class="el-input_tow"  style="border: none;"-->
<!--                            v-model="passApplyReason" placeholder="请输入内容"></el-input>-->
<!--                  &lt;!&ndash;                <div v-else style="border-bottom: solid 1px #BBBBBB;min-width: 140px;text-align: center">{{passApplyReason}}</div>&ndash;&gt;-->
<!--                </div>-->
                <div class="item">
                </div>
                <div class="item">
                </div>
                <div class="item">
                </div>
                <div class="item" v-if="active===0" >
                  附&emsp;&emsp;件：<a @click="funUrlPdf" style="text-decoration: none;margin-left: 22px" href="javascript:;" >添加附件</a>
                </div>
                <div class="item" v-else>
                  附&emsp;&emsp;件：
                  <a @click="funUrlLookPdf" style="text-decoration: none;margin-left: 22px" href="javascript:;" >查看附件</a>
                </div>
              </div>
            </div>
            <div style="margin-top: 40px">
              <el-badge v-if="formPublishSon.handleNum > 0" :value="formPublishSon.handleNum" :max="99" class="item">
                <el-button @click="chuLijilu" size="small" type="primary">处理记录</el-button>
              </el-badge>
              <el-button v-else @click="chuLijilu" size="small" type="primary">处理记录</el-button>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center;margin-top: 50px" >
        <el-button size="small" @click="clickCancel"
                   style="width: 80px;background-color: #D8E7FC;border: none">取消
        </el-button>
        <el-button size="small"  type="primary" @click="clickSubmitBaocun"
                   style="width: 80px;margin-left: 40px;background-color: #409EFF ;border: none">保存
        </el-button>
        <el-button size="small"  type="primary" @click="clickAudit"
                   style="width: 80px;margin-left: 40px;background-color: #409EFF;border: none">提交
        </el-button>
      </div>

      <!--      处理记录弹框-->
      <r-e-dialog title="处理记录" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                  @click-cancel="billListClosejilu" v-dialog-drag>
        <r-e-table :query="formSearch" class="bg-white" ref="orderTableRef" :dataRequest="getWordListTableData"
                   :columns="tableColumn"
                   tooltip-effect="light" :height="500" :cell-style="cellStyle">
          <template slot="empty">
            <el-empty/>
          </template>

        </r-e-table>
      </r-e-dialog>
    </r-e-dialog>
    <dialog-save-contract-file ref="dialogSaveContractFile" :currentHouse="formPublishSon"
                               :contractInfo="formPublishSon" @attachment-click="attachmentClick" />
    <dialogLookContractFile ref="dialogLookContractFile"></dialogLookContractFile>
  </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageError} from '@custom/message'
import {
  iotTagLockTemporary,
  GETHistoryTotalDuration,
  offLinePassApprovalPUT,
  POSToffLinePassSubmitList,
  HousingapprovalPOST, SubmitHousingapprovalPOST
} from '@/api/door-management'
import el from "element-ui/src/locale/lang/el";
import {tableWorkOrderColumnjilu} from "@/views/rental-management/approval-process-workorder/data";
import {getHousingCodeInfoGET} from "@/api/work-order-management";
import DialogSaveContractFile
  from "@/views/rental-management/approval-process-workorder/components/pant/dialog-save-contract-file.vue";
import DialogLookContractFile
  from "@/views/rental-management/approval-process-workorder/components/pant/dialog-look-contract-file.vue";
export default {
  name: 'dialog-set-lock-temporary',
  components: {DialogLookContractFile, DialogSaveContractFile},
  props: {
    equipmentData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      billSwitch:false,
      days: null,
      daysnb: null,
      totalHistoryDuration: null,
      timeItr: null,
      dialogVisible: false,
      formPublish: {
        apartmentUuid: null,
        phone: null,
        isDelete: false,
        days: 1 // 默认选中值为1
      },
      formSearch: {},
      // components: {
      //   dialogSaveContractFile:() => import('@/views/rental-management/approval-process-workorder/components/pant/dialog-save-contract-file.vue'),
      //   dialogLookContractFile:() => import('@/views/rental-management/approval-process-workorder/components/pant/dialog-look-contract-file.vue')
      // },
      rules: {
        days: [
          {required: true, message: '请选择天数', trigger: 'change'}
        ],
        phone: [{required: true, message: '请填写手机号', trigger: 'blur'}],
        isDelete: [
          {
            required: true,
            message: '请选择是否删除历史密码',
            trigger: 'change'
          }
        ]
      },
      cycle: [],
      loadingOptions: {
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      },
    //   审批处理弹框数据
      dialogVisibleson:false,
      formPublishSon:{},
      active:0,
      tableColumn: tableWorkOrderColumnjilu(this),
      userName:'',
      passApplyReason:'',//申请理由
      topTlile:'',
    }
  },
  methods: {
    getWordListTableData(params) {
      return getHousingCodeInfoGET(params);
    },
    // 初始化获取时间
    GETHistoryTotalDurationmet() {
      GETHistoryTotalDuration({apartmentUuid: this.equipmentData.apartmentUuid}).then(({info}) => {
        // const itr = this.isTimestampGreaterThanTenDaysAgo(info)
        console.log(info)
        // alert(itr)
        // 是否超过15天：aboveFifteen      是否超过10天：aboveTen
        this.timeItr = 1
        if (info.aboveTen) this.timeItr = 2
        if (info.aboveFifteen) this.timeItr = 3

        this.totalHistoryDuration = info['历史累计时间戳']
        this.days = info['历史累计时间字符串']
        this.daysnb = info['历史累计时间戳']
        console.log(info)
      })
    },
    // 打开弹框事件
    openDialog(lockId,fUuid) {
      this.GETHistoryTotalDurationmet()
      const oldFormPublish = this.formPublish
      this.formPublish = {...oldFormPublish, apartmentUuid: lockId}
      this.userName = localStorage.getItem("userName");
      this.fangyuanapartmentUuid = fUuid
      this.dialogVisible = true
    },
    // 获取合同审批内容接口
    POSToffLinePassSubmitList(){
      let { apartmentUuid,phone, days,  } =  this.formPublish
      let totalHistoryDuration = this.daysnb
      POSToffLinePassSubmitList( {apartmentUuid:this.fangyuanapartmentUuid,phone, days, totalHistoryDuration}).then((res) => {
        console.log( res,'99')
        this.formPublishSon = res.info
        this.topTlile =  res.info.totalHistoryDurationStr.split("天")[0] > 15 ? true : false
        console.log(this.formPublishSon)
})

    },
    // 关闭密码修改弹框事件
    clickCancel() {
      this.dialogVisible = false
      this.formPublish = {
        phone: null,
        isDelete: true,
        days: null
      }
      this.dialogVisibleson = false
    },
    // 同离线密码历史累计时长已超10天事件
    timeItrTow(){
      let that = this
      if (this.timeItr === 2) {
        // alert(that.timeItr)
        this.$alert('<strong>该合同离线密码历史累计时长已超10天<br>需审批通过方可设置成功，是否继续？</strong>', '提示', {
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true,
          showCancelButton: true,
          center: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(() => {
          this.dialogVisibleson = true
          // const loadingOptions = this.loadingOptions
          // const loading = this.$loading({...loadingOptions})
          // let {apartmentUuid, phone, isDelete, days} = that.formPublish
          // if (apartmentUuid === null) {
          //   MessageError('当前房源没有智能门锁无法发送')
          //   return
          // }
          // apartmentUuid = that.equipmentData.apartmentUuid
          // offLinePassApprovalPUT({
          //   apartmentUuid,
          //   phone,
          //   isDelete,
          //   days,
          //   totalHistoryDuration: this.totalHistoryDuration,
          // }).then(res => {
          //   console.log(res)
          //   loading.close()
          //   this.clickCancel()
          //   this.$message({
          //     type: 'info',
          //     message: '申请成功'
          //   });
          // }).catch(err => {
          //   loading.close()
          // })

        })
      }
    },
    // 同离线密码历史累计时长已超15天事件
    timeItrThree(){
      let that = this
      if (that.timeItr === 3) {
        // alert(that.timeItr)
        this.$alert('<strong>该合同离线密码历史累计时长已超15天<br>需审批通过方可设置成功，是否继续？</strong>', '提示', {
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true,
          showCancelButton: true,
          center: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(() => {
          this.dialogVisibleson = true

      //     const loadingOptions = this.loadingOptions
      //     const loading = this.$loading({...loadingOptions})
      //     let {apartmentUuid, phone, isDelete, days} = that.formPublish
      //     if (apartmentUuid === null) {
      //       MessageError('当前房源没有智能门锁无法发送')
      //       return
      //     }
      //     apartmentUuid = that.equipmentData.apartmentUuid
      //     this.clickCancel()
      // offLinePassApprovalPUT({
      //   apartmentUuid,
      //   phone,
      //   isDelete,
      //   days,
      //   totalHistoryDuration: this.totalHistoryDuration,
      // }).then(res => {
      //   console.log(res)
      //   loading.close()
      //   this.$message({
      //     type: 'info',
      //     message: '申请成功'
      //   });
      //   this.clickCancel()
      // }).catch(err => {
      //   console.log(err)
      //   loading.close()
        // MessageError('申请失败请联系管理员')
      // })

      })
      }
    },
    clickSubmit() {
      // this.$refs['CipherProcessingRef'].CipherProcessingClick()
      let that = this
      this.$refs['formPublish'].validate(valid => {
        if (valid) {
          if (this.timeItr !== 1) this.POSToffLinePassSubmitList()//获取工单信息

          // 密码审批代码暂时注释--已经迁移至上方
          if(this.timeItr === 2) this.timeItrTow()
          if(this.timeItr === 3) this.timeItrThree()
          if (this.timeItr === 1) {
            // alert(that.timeItr)
            const loadingOptions = this.loadingOptions
            const loading = this.$loading({...loadingOptions})
            let {apartmentUuid, phone, isDelete, days} = that.formPublish
            if (apartmentUuid === null) {
              MessageError('当前房源没有智能门锁无法发送')
              return
            }
            apartmentUuid = that.equipmentData.apartmentUuid
            iotTagLockTemporary({apartmentUuid, phone, isDelete, days})
                .then(res => {
                  loading.close()
                  MessageSuccess('密码发送成功')
                  that.clickCancel()
                })
                .catch(err => {
                  MessageError('密码发送失败')
                  loading.close()
                })
          }
          //   alert(that.timeItr)
        //   以下是老代码
        //     const loadingOptions = this.loadingOptions
        //     const loading = this.$loading({...loadingOptions})
        //     let {apartmentUuid, phone, isDelete, days} = that.formPublish
        //     if (apartmentUuid === null) {
        //       MessageError('当前房源没有智能门锁无法发送')
        //       return
        //     }
        //     apartmentUuid = that.equipmentData.apartmentUuid
        //     iotTagLockTemporary({apartmentUuid, phone, isDelete, days})
        //         .then(res => {
        //           loading.close()
        //           MessageSuccess('密码发送成功')
        //           that.clickCancel()
        //         })
        //         .catch(err => {
        //           MessageError('密码发送失败')
        //           loading.close()
        //         })
        //   此处结束
        }
      })
    },
    // 操作记录关闭弹框事件
    billListClosejilu() {
      // 关闭处理弹框事件
      this.billSwitch = false;
    },
    chuLijilu(type) {
      // if (type === '合同') {
      //   this.tableColumn = tableWorkOrderColumnjiluHETONG(this)
      // }
      // // 这里添加处理记录的逻辑
      this.billSwitch = true
      console.log('点击了处理记录按钮');
    },
    // 审批提交
    clickAudit(){
      if(!this.passApplyReason){
        MessageError('请输入申请原因')
        return
      }
      let {apartmentUuid,totalHistoryDuration,apartmentId,leasorUuid,leasorId,contractUuid,days,contractId,phone,attachment} = this.formPublishSon
      let data = {attachment,phone,days,totalHistoryDuration,contractId,apartmentUuid,apartmentId,leasorUuid,leasorId,contractUuid,passApplyReason:this.passApplyReason,deleted:this.formPublish.isDelete,}
      console.log(data)
      SubmitHousingapprovalPOST(data).then(res => {
        console.log(res)
        this.clickCancel()
        MessageSuccess(res.msg)
      }).catch(err => {
        this.clickCancel()
        // MessageError(err.msg)
      })
    },
    // 审批保存
    clickSubmitBaocun(){
      if(!this.passApplyReason ){
        MessageError('请输入申请原因')
        return
      }
      // passApplyReason
      let {apartmentUuid,totalHistoryDuration,apartmentId,leasorUuid,leasorId,contractUuid,days,contractId,phone,attachment} = this.formPublishSon
      let data = {attachment,phone,days,totalHistoryDuration,contractId,apartmentUuid,apartmentId,leasorUuid,leasorId,contractUuid,passApplyReason:this.passApplyReason,deleted:this.formPublish.isDelete,}
      console.log(data)
      HousingapprovalPOST(data).then(res => {
        console.log(res)
        this.clickCancel()
        MessageSuccess(res.msg)
      }).catch(err => {
        this.clickCancel()
        MessageError(err.msg)
      })
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      let {operateType} = row;
      // if (workOrderStatusName === '待资产部审核' || workOrderStatusName === '待财务部审核' || workOrderStatusName === '待管理员审核') return {color: '#FF0000'};
      if (operateType === '驳回' && columnIndex === 1) return {color: '#FF3718'};
      // if (operateType === '待财务部审核' && columnIndex === 2) return {color: '#FF3718'};
      // if (operateType === '待管理层审核' && columnIndex === 2) return {color: '#FF3718'};
      // if (operateType === '待发起' && columnIndex === 2) return {color: '#FF3718'};

      //  字体颜色
    },
    // 上传附件
    attachmentClick(uuid){
      console.log(uuid)
      // alert(uuid)
      //
      // this.formPublishSon.attachment = [...uuid.filePDFList,...uuid.imgList].join(",")
      // this.formPublishSon.attachmentPdf = uuid.filePDFList
      // this.formPublishSon.attachmentImg = uuid.imgList
      // console.log(this.formPublish,'上传完点击确认然后回馈给父组件')


      console.log(uuid)
      // alert(uuid)
      console.log(uuid)
      let MYfilePDFList = []
      uuid.filePDFList.forEach(item => {
        MYfilePDFList.push(item.uuid)
      })
      this.formPublishSon.attachment = [...MYfilePDFList,...uuid.imgList].join(",")
      this.formPublishSon.attachmentPdf = uuid.filePDFList
      this.formPublishSon.attachmentImg = uuid.imgList
      console.log(this.formPublishSon,'上传完点击确认然后回馈给父组件')
    },
    // 上传pdf事件
    funUrlPdf(){
      this.formPublishSon.attachmentImg ? this.formPublishSon.contractPhotos = 'IMG+-+' + this.formPublishSon.attachmentImg : this.formPublishSon.contractPhotos = null
      // this.formPublish.attachmentPdf ?  this.formPublish.pdfUrl = 'PDF+-+' +  this.formPublish.attachmentPdf: this.formPublish.pdfUrl = null
      if(this.formPublishSon.attachmentPdf){
        let pdfList = []
        this.formPublishSon.attachmentPdf.forEach(item => {
          pdfList.push(item.uuid)
        })
        pdfList ?  this.formPublishSon.pdfUrl = 'PDF+-+' +  pdfList : this.formPublishSon.pdfUrl = null
      }else {
        this.formPublishSon.pdfUrl = null
      }
      //   IMG+-+ PDF+-+
      // if(this.formPublish)
      console.log(this.formPublishSon,'formPublish--传子组件数据')
      this.$refs["dialogSaveContractFile"].openDialog(this.formPublishSon);
    },
    funUrlLookPdf(){
      this.formPublishSon.attachmentImg ? this.formPublishSon.contractPhotos = 'IMG+-+' + this.formPublishSon.attachmentImg : this.formPublishSon.contractPhotos = null
      if(this.formPublishSon.attachmentPdf){
        let pdfList = []
        this.formPublishSon.attachmentPdf.forEach(item => {
          pdfList.push(item.uuid)
        })
        pdfList ?  this.formPublishSon.pdfUrl = 'PDF+-+' +  pdfList : this.formPublishSon.pdfUrl = null
      }else {
        this.formPublishSon.pdfUrl = null
      }

      this.$refs["dialogLookContractFile"].openDialog(this.formPublishSon);
    }
  },
  watch: {}
}
</script>

<style scoped>

.box_tap {
  border-left: #EEB765 2px solid;
  width: 500px;
  height: 29px;
  line-height: 29px;
  padding-left: 5px;
}

.box_bnt {
  border: #BBBBBB solid 1px;
  width: 100%;
  height: 80px;
  margin-top: 10px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 项目之间的间距，可选 */
  padding: 10px; /* 容器内边距 */
}

.box_bnt_zujin {
  border: #BBBBBB solid 1px;
  width: 100%;
  height: 300px;
  margin-top: 30px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 项目之间的间距，可选 */
  padding: 10px; /* 容器内边距 */
}

.item {
  flex: 1 1 calc(25% - 20px); /* 每个项目占据25%宽度减去间距 */
  color: black;
}

.el-input_tow {
  .el-input__inner {
    border: none;
    border-bottom: #8c939d 1px solid;
    border-radius: 0px;
  }

}

.input_sty {
  margin-top: -10px;
  margin-left: -10px;
  width: 200px
}


</style>
